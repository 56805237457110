import * as React from "react";
import { PartnersLogosType } from "@types";
import styles from "./PartnersGrid.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PartnersGrid: React.FC<{ data: PartnersLogosType[] }> = ({
  data: textLogoListFive,
}) => {
  const createImage = (logo: PartnersLogosType) => {
    return getImage(logo.textLogoItem.localFile);
  };

  const grid = textLogoListFive.map((logo, index) => {
    let image = createImage(logo);
    return image ? (
      <GatsbyImage
        key={index}
        image={image}
        alt={logo?.textLogoItem?.altText || ""}
      />
    ) : (
      <img
        src={logo?.textLogoItem?.mediaItemUrl}
        alt={logo?.textLogoItem?.altText || ""}
      />
    );
  });

  const gridMobile = textLogoListFive.map((logo, index) => {
    let image = createImage(logo);

    return (
      index <= 5 &&
      (image ? (
        <GatsbyImage
          key={`${index}-${Date.now()}`}
          image={image}
          alt={logo?.textLogoItem?.altText || ""}
        />
      ) : (
        <img
          src={logo?.textLogoItem?.mediaItemUrl}
          alt={logo?.textLogoItem?.altText || ""}
        />
      ))
    );
  });

  return (
    <div className={styles.logosWrapper}>
      <div className={`hide-for-small ${styles.logosContainer}`}>
        <div className={styles.logosFlex}>{grid}</div>
      </div>

      <div className={`hide-for-large ${styles.logosContainer}`}>
        <div className={styles.logosGrid}>{gridMobile}</div>
      </div>
    </div>
  );
};

export default PartnersGrid;
