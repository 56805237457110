import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";

import BrandCampaignHeroContainer from "../../containers/BrandCampaignHeroContainer/BrandCampaignHeroContainer";
import CustomerPersonaBannerContainer from "../../containers/CustomerPersonaBannerContainer/CustomerPersonaBannerContainer";
import DefaultLayoutShortNav from "../../layouts/DefaultLayoutShortNav";
import HowYouGrowContainer from "../../containers/HowYouGrowContainer";
import OtherProductsContainer from "../../containers/OtherProductsContainer";
import PartnersGridContainer from "../../containers/PartnersContainers/PartnersGridContainer/PartnersGridContainer";
import WhatWeDeliver from "../../containers/WhatWeDeliverContainer";
import WhySimplePracticeSection from "../../components/WhySPSection";
import B2BHomeContainer from "../../containers/B2BContainer/B2BHomeContainer";

import {
  BrandCampaignHeroType,
  CustomerPersonaType,
  GlobalFooterLargeCtaHType,
  HowYouGrowSectionType,
  OtherProductsType,
  PartneredWithLogosPType,
  WhatWeDeliverType,
  WhySimplepracticeSection,
  YoastSEOType,
} from "@types";

import styles from "./styles.module.scss";
import { deIndexSeo } from "../../utils/deIndexSeo";

type HomeShortNavPageType = {
  wpPage: {
    seo: YoastSEOType;
    title: string;
    template: {
      brandCampaignHero: BrandCampaignHeroType;
      customerPersonas: CustomerPersonaType;
      globalFooterLargeCtaH?: GlobalFooterLargeCtaHType;
      howYouGrowSection: HowYouGrowSectionType;
      partneredWithLogosP: PartneredWithLogosPType;
      products: OtherProductsType;
      whatWeDeliverSection: WhatWeDeliverType;
      whySimplepracticeSection: WhySimplepracticeSection;
      resourcesBlogRelationship: Queries.WpHomeTemplate_Resourcesblogrelationship;
    };
  };
  location: PageProps["location"];
};

const HomePage: React.FC<PageProps<HomeShortNavPageType>> = ({
  data,
  location,
}) => {
  const { wpPage } = data;
  const {
    template: {
      brandCampaignHero,
      partneredWithLogosP,
      whySimplepracticeSection,
      products,
      howYouGrowSection,
      whatWeDeliverSection,
      customerPersonas,
      globalFooterLargeCtaH,
      resourcesBlogRelationship,
    },
  } = wpPage;

  const whatWeDeliverProps = {
    ...whatWeDeliverSection,
    resourcesBlogRelationship,
  };

  return (
    <DefaultLayoutShortNav
      pathname={location.pathname}
      hasFooterLargeCta={false}
      globalFooterLargeH={globalFooterLargeCtaH}
    >
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}

      <BrandCampaignHeroContainer
        data={brandCampaignHero}
        alter={true}
        key="var-0"
      />

      <PartnersGridContainer
        className={styles.partnersSlider}
        props={partneredWithLogosP}
        noSlider={true}
        home
      />
      <B2BHomeContainer />
      <WhySimplePracticeSection
        whySpContent={{ ...whySimplepracticeSection }}
      />
      <CustomerPersonaBannerContainer props={customerPersonas} />
      <HowYouGrowContainer
        sectionData={howYouGrowSection}
        location={location}
      />
      <OtherProductsContainer content={products} />
      <WhatWeDeliver props={whatWeDeliverProps} />
    </DefaultLayoutShortNav>
  );
};

export const query = graphql`
  query HomeShortNavTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpHomeShortNavTemplate {
          ...FragmentBrandCampaignHeroHomeShortNav
          ...FragmentCustomerPersonasShortNav
          ...FragmentHowYouGrowShortNav
          ...FragmentOtherProductsShortNav
          ...FragmentPartneredWithLogosShortNav
          ...FragmentWhatWeDeliverShortNav
          ...FragmentWhySimplePracticeShortNav
          ...FragmentResourcesBlogShortNav

          globalFooterLargeCtaH {
            navCtaLink
            navCtaText
            smartDisplayToggle
            ctaBannerLargeTitle
            ctaBannerLargeSubcopy
            ctaBannerLargeCtaText
            ctaBannerLargeCta
            ctaBannerLargeBackgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
            ctaBannerLargeList {
              ctaBannerLargeListItem
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
