import React from "react";
import BlueLinkWithArrow from "../../components/Buttons/BlueLinkWithArrow/BlueLinkWithArrow";
import BookingToBilling from "../../components/BookingToBilling/index";
import BookingToBillingQuery from "../../assets/StaticQueries/BookingToBilling.query";

import styles from "../B2BContainer/B2BContainer.module.scss";

type B2BHomeContainerPropsType = {
  className?: string;
};

const B2BHomeContainer: React.FC<B2BHomeContainerPropsType> = props => {
  const b2b = BookingToBillingQuery();

  return (
    <div>
      <section className={styles.b2bContainerHome}>
        <div className={`full-width w--704 text--alignment--center`}>
          <h2
            className={styles.b2bTitle}
            dangerouslySetInnerHTML={{ __html: b2b?.title! }}
          ></h2>
          <div className={styles.b2bSubcopyContainer}>
            {b2b?.subcopy && (
              <span className={styles.b2bSubcopy}>
                {b2b?.subcopy}
                <BlueLinkWithArrow
                  className={styles.inlineLink}
                  link={b2b?.featureLink!}
                  linkCopy={b2b?.featureLinkText!}
                />
              </span>
            )}
          </div>
          <BookingToBilling bookingToBillingFeatureList={b2b?.featureList!} />
        </div>
      </section>
    </div>
  );
};

export default B2BHomeContainer;
